import React, { useState } from 'react';
import '../css/NewTdTableSp.css';

function NewTdTableSP({ num, sp }:{num: number, sp: string}):JSX.Element {

    const [spSheet, setSpSheet] = useState(sp);

    return (
        <tr>
            <td className="table-sp-td" id="sp-value"><input type="number" id={num.toString()} placeholder="Digite aqui..." value={spSheet} onChange={(event):void => setSpSheet(event?.target.value)} /></td>
        </tr>
    )
}

export default NewTdTableSP;

