/* eslint-disable import/no-named-as-default-member */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Firebase from "../config/firebase";
import LoginComp from "../components/LoginComp";

function Login(): JSX.Element {
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [erro, setErro] = useState(false);
  

  const clearInputs = (): any => {
    setEmail("");
    setPassword("");
  };

    async function handleLogin():Promise<any> {
    setErro(false);
    await Firebase.auth()
      .signInWithEmailAndPassword(email, password)
      .catch((err: { code: any; message: React.SetStateAction<string> }) => {
        setErro(true);
        
        switch (err.code) {
          case "auth/invalid-email":
            setPasswordError("");
            setEmailError("E-mail inválido");
            break;
          case "auth/user-disabled":
            setPasswordError("");
            setEmailError("Usuário desativado");
            break;
          case "auth/user-not-found":
            setPasswordError("");
            setEmailError("Usuário não existe!");
            break;
          case "auth/wrong-password":
            setEmailError("");
            setPasswordError("Senha incorreta");
            break;
          default:
        }
      }).then((result) => {
        if(!(result === undefined)){
          history.push('/home');
        }
      });
  };

  const handleLogout = (): any => {
    Firebase.auth().signOut();
  };

  const authListener = (): any => {
    // eslint-disable-next-line @typescript-eslint/no-shadow

    Firebase.auth().onAuthStateChanged((user: any) => {
      if (user) {
        clearInputs();
        setUser(user);
      } else {
        setUser("");
      }
    });
  };

  useEffect((): any => {
    authListener();
  }, []);

  return (
    <div className="Login">
      <LoginComp
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        handleLogin={handleLogin}
        emailError={emailError}
        passwordError={passwordError}
      />
    </div>
  );
}

export default Login;